<template>
  <div class="container">
    <PrimePreloader v-if="loading"/>
    <div v-else>
      <h3 v-if="status.text" class="text-center my-5" :class="status.textColor">
        {{ status.text }}
      </h3>
    </div>
  </div>
</template>

<script>

  import httpClient from "@/services/http.service"


  export default {
    name: "LessonVisiting",
    data() {
      return {
        loading: true,
        schedule_id: +this.$route.params.id || 0,
        message: '',
        status: {},
        statuses: {
          success: {
            text: 'QR успешно просканирован',
            textColor: 'text-success'
          },
          repeat: {
            text: 'Вы уже просканировали QR',
            textColor: 'text-secondary'
          },
          error: {
            text: 'Произошла ошибка',
            textColor: 'text-danger'
          }
        }

      }
    },
    methods: {
      async studentVisit(schedule_id) {
        try {
          const {status, data} = await httpClient.post(`student_data/student-visiting-journal/student-visit?schedule_id=${schedule_id}`)
          if (status === 200) {
            if (data) {
              this.status = this.statuses.success
              return this.$message({text: this.status.text})
            }

            this.status = this.statuses.repeat
            return this.$messageInfo({text: this.status.text})
          }
        } catch (e) {
          this.status = this.statuses.error
          return this.$error({text: this.status.text})
        }
      }
    },
    async mounted() {
      if (this.schedule_id) {
        await this.studentVisit(this.schedule_id)
      }
      this.loading = false
    }
  }
</script>